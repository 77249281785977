import * as React from "react"
import Menu from "../components/menu"
import Footer from "../components/footer"
import { StaticImage } from "gatsby-plugin-image"
import { subtitle, title, textContainer } from "../styles"
import clsx from "clsx"
import Seo from "../components/seo"

// markup
const DetailFsPage = (props) => {
  return (
    <div> 
      <Seo />
      <Menu/>
      <div className="max-w-screen-xl mx-5 xl:mx-auto md:grid md:grid-cols-3 md:gap-8">

        <div className="max-w-screen-lg mt-12 text-center col-span-2">
          <StaticImage className="w-4/6 md:w-full"
            src="../images/fs-mockup.png"
            placeholder="blurred"
            loading="eager"
          />
        </div>

        <div className="max-w-screen-sm mt-12">
          <h1 className={ title }>
            Logo - F.S Business Management
          </h1>
          <div className={ clsx(textContainer, "text-justify") }>
            <p className="mb-4">Creation of company branding identity for a new company, F.S Business Management, based in Belgium. The objective for this project was to create a logo that would include the name of the company and would still remain clean, fresh, simple and at the same time subtile but readable.</p>
          </div>

          <div className={ textContainer }>
            <h2 className = { subtitle }> Client </h2>
            <p className="mb-4">F.S Business Management</p>
            <h2 className = { subtitle }> Type </h2>
            <p className="mb-4">Logo</p>
            <h2 className = { subtitle }> Year </h2>
            <p className="mb-4">2019</p>
          </div>
        </div>

      </div>
      <Footer/>
    </div>
     
  
  )
}


export default DetailFsPage